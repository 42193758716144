<template>
  <div class="divBox" v-loading="loading">
    <el-card>区域允许购买</el-card>
    <el-card style="margin-top: 10px">
      <el-form
        :model="tableForm"
        ref="tableForm"
        label-width="100px"
        class="demo-tableForm"
      >
        <el-form-item label="是否开启:" prop="delivery">
          <el-switch
            @change="saveSwitch"
            v-model="tableForm.status"
            :inactive-value="0"
            :active-value="1"
          ></el-switch>
        </el-form-item>
        <el-form-item label="允许购买区域:" prop="desc">
          <el-card shadow="never" v-if="tablist.length"
            ><el-tag v-for="(item,index) in tablist" :key="index" type="info" style="margin-right: 5px;">{{item.name}}</el-tag></el-card
          >

          <el-button  v-if="tablist.length" type="primary" @click="add" size="mini">编辑</el-button>
          <el-button type="text" v-else icon="el-icon-plus" @click="add"
            >添加区域</el-button
          >
        </el-form-item>
      </el-form></el-card
    >
    <el-dialog title="选择区域" :visible.sync="dialogVisible" width="600px">
      <el-button size="mini" @click="choiceAll">全选</el-button>
      <el-button size="mini" @click="choiceAllNone">取消全选</el-button>
      <div class="content">
        <el-tree
          :default-expanded-keys="regionId"
          :data="list"
          ref="tree"
          show-checkbox
          node-key="id"
          :props="defaultProps"
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRegionList,
  RegionListSave,
  getStoreRegion,
} from "@/api/system/index";
import moment from "moment";
export default {
  name: "regionRestrict",
  data() {
    return {
      dialogVisible: false,
      isIndeterminate: false,
      regionId: [],
      list: [],
      listItem: [],
      tablist: [],
      loading:false,
      defaultProps: {
        children: "children",
        label: "name",
      },
      tableForm: {
        status: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true
      getRegionList().then((res) => {
        this.list = res.data;
        this.list.map((item, index) => {
          item.children.map((itmes, indexs) => {
            itmes.children = null;
          });
        });
        this.getStoreRegionFun();
        this.loading = false
      }).catch((res)=>{
        this.loading = false
      });
    },
    getStoreRegionFun() {
      getStoreRegion().then((res) => {
        this.tableForm.status = res.data.status;
        let arr = JSON.parse(res.data.city)
        this.tablist = arr
        this.dialogVisible = false
        arr.map((item,index)=>{
        this.regionId.push(item.id)
      })
 
      });
 
    },
    //全选
    choiceAll(){
      this.regionId=[]
      this.list.map((item,index)=>{
        this.regionId.push(item.id)
        if(item.children){
          item.children.map((itmes,indexs)=>{
            this.regionId.push(itmes.id)
          })
        }
      })
      this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.regionId); //给树节点赋值回显
        })
    },
    choiceAllNone(){
      this.regionId=[]
      this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.regionId); //给树节点赋值回显
        })
    },
    add() {
      this.dialogVisible = true;
      this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.regionId); //给树节点赋值回显
        })
    },
    saveSwitch(){
       let arr = []
       RegionListSave({
        status: this.tableForm.status,
        city: this.tablist,
      }).then((res) => {
        this.dialogVisible = false;
        this.$message.success('修改成功')
        this.getList();
      });
    },
    save() {
      let checkedKeys = this.$refs.tree.getCheckedKeys();
      let arr = [];
      this.list.map((item, index) => {
        if (checkedKeys.includes(item.id)) {
          let obj = {
            id: item.id,
            name: item.name,
          };
          arr.push(obj);
        }
         if(item.children){
          item.children.map((items,indexs)=>{
            if (checkedKeys.includes(items.id)) {
          let objs = {
            id: items.id,
            name: items.name,
          };
          arr.push(objs);
        }
          })

         }
      });
      RegionListSave({
        status: this.tableForm.status,
        city: arr,
      }).then((res) => {
        this.dialogVisible = false;
        this.$message.success('修改成功')
        this.getList();
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
.content {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  padding: 20px 0;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
}
.checkboxGroupItem {
  margin-bottom: 13px;
}
</style>
  